import { BorderedWrapper, Button, Hint, Modal, ReferalCalculatorDialog } from 'components';
import { StyledReferrralLevels } from './styles';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/store';
// import { selectReferralStats } from 'store/slices/userReferralSlice';
import { ESliceDataFetchingStatus } from 'types/common';
import { formateHashUnits } from 'utils';
interface IReferrralLevelsProps {
  children?: React.ReactNode;
}
export const ReferrralLevels = ({ children }: IReferrralLevelsProps) => {
  const { t } = useTranslation();

  const { referralStatsStatus, referralStats } = useAppSelector((state) => state.userReferrals);
  const { referralCurrentRate, referralLevel, referralMaxRate } = referralStats;

  const [isMountModal, setIsMountModal] = useState(false);
  const currentRateParagraphTexts = t('referralsPage.levels.currentRate', {
    returnObjects: true,
  }) as string[];

  const [showAddWorkerDialog, setShowAddWorkerDialog] = useState(false);
  const [dialogType, setDialogType] = useState<'calulator' | 'levelHint' | null>(null);
  const showReferralCalculatorHandler = () => {
    setShowAddWorkerDialog(true);
    setDialogType('calulator');
  };
  const showHintDialogHandler = () => {
    setShowAddWorkerDialog(true);
    setDialogType('levelHint');
  };
  const cloaseModalHandler = () => {
    setShowAddWorkerDialog(false);
    setDialogType(null);
  };

  useEffect(() => {
    if (showAddWorkerDialog) {
      setIsMountModal(true);
    }

    if (!showAddWorkerDialog) {
      setTimeout(() => setIsMountModal(false), 500);
    }
  }, [showAddWorkerDialog]);

  return (
    <StyledReferrralLevels>
      <Modal show={isMountModal}>
        <ReferalCalculatorDialog onClose={cloaseModalHandler} show={isMountModal} />
      </Modal>
      <BorderedWrapper>
        {referralStatsStatus === ESliceDataFetchingStatus.loading ? (
          'Loading...'
        ) : (
          <div className="content">
            <h2 className="levels-heading">
              {t('referralsPage.levels.heading')}
              <div className="hint-container">
                <Hint>?</Hint>
              </div>
            </h2>
            <div className="level-value">{referralLevel ? referralLevel : 'n/a'}</div>
            <div className="current-hash-rate">
              <p className="current-hash-rate-title">{currentRateParagraphTexts[0]}</p>
              <p className="current-hash-rate-value">
                <span>
                  {referralCurrentRate ? formateHashUnits(referralCurrentRate) : 'n/a'} TH/s
                </span>{' '}
                <span>{currentRateParagraphTexts[1]}</span>{' '}
                <span>{referralMaxRate ? formateHashUnits(referralMaxRate) : 'n/a'} TH/s</span>
              </p>
            </div>
            <div className="calc-button">
              <Button
                title={t('referralsPage.levels.calculatorModalButton')}
                onClick={showReferralCalculatorHandler}
              />
            </div>
          </div>
        )}
      </BorderedWrapper>
    </StyledReferrralLevels>
  );
};
