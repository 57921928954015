import { BorderedWrapper, CustomCheckbox } from 'components';
import { StyledNotifications } from './styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/store';
import { IUserNotificationSettings } from 'types/settings';
import { UserRepository } from 'repositories/UserRepository';
import { fetchUserNotificationSettings } from 'store/thunks/userSettingThunks';
import toast from 'react-hot-toast';
import { EApiResponseStatus } from 'types/api';
interface INotificationsProps {
  children?: React.ReactNode;
}
export const Notifications = ({ children }: INotificationsProps) => {
  const { notification } = useAppSelector((state) => state.userSettings);
  const { token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const notificationElements = ['payouts', 'worker', 'login', 'news', 'referral'] as const;

  const onChangeCheckBoxHandler =
    (notificationElement: (typeof notificationElements)[number], source: 'email' | 'telegram') =>
    async (isChecked: boolean) => {
      try {
        if (!token) throw Error('unauthorised');
        if (notification.email.userId !== null && notification.telegram.userId !== null) {
          const settings: IUserNotificationSettings = {
            email: {
              ...notification.email,
              userId: notification.email.userId,
            },
            telegram: { ...notification.telegram, userId: notification.telegram.userId },
          };
          settings[source][notificationElement] = isChecked;
          const response = await UserRepository.setUserNotificationSettings(settings, token);
          if (response.status === EApiResponseStatus.FAILURE) throw Error(response.error);
          dispatch(fetchUserNotificationSettings());
        }
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          toast.error(error.message);
        } else {
          toast.error('failed to update settings');
        }
      }
    };

  return (
    <StyledNotifications>
      <BorderedWrapper>
        <div className="notifications">
          <div className="notification__row header">
            <div className="notitication_element title header">
              {t('settingsPage.notifications.header.label')}
            </div>
            <div className="notitication_element header checkbox-container first">
              {t('settingsPage.notifications.header.mail')}
            </div>
            <div className="notitication_element header checkbox-container second">
              <span className="short">{t('settingsPage.notifications.header.telegramShort')}</span>
              <span className="full">{t('settingsPage.notifications.header.telegram')}</span>
            </div>
          </div>

          {notificationElements.map((notificationElement, index) => (
            <div key={notificationElement + index} className="notification__row">
              <div className="notitication_element title">
                {t(`settingsPage.notifications.elements.${notificationElement}`)}
              </div>
              <div className="notitication_element checkbox-container first">
                <CustomCheckbox
                  onChange={onChangeCheckBoxHandler(notificationElement, 'email')}
                  initialChecked={notification.email[notificationElement]}
                />
              </div>
              <div className="notitication_element checkbox-container second">
                <CustomCheckbox
                  onChange={onChangeCheckBoxHandler(notificationElement, 'telegram')}
                  initialChecked={notification.telegram[notificationElement]}
                />
              </div>
            </div>
          ))}
        </div>
      </BorderedWrapper>
    </StyledNotifications>
  );
};
