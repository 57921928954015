import { Input, Modal, OutlinedButton, QRCodeDialog, Toggler } from 'components';
import { StyledEditablePhoneInput } from './styles';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as EditButton } from 'assets/icons/edit-icon.svg';
import { useAppDispatch, useAppSelector } from 'store/store';
import { UserRepository } from 'repositories/UserRepository';
import { fetchUserSettings } from 'store/thunks/userSettingThunks';
import toast from 'react-hot-toast';
import { EApiResponseStatus } from 'types/api';

interface IEditablePhoneInputProps {
  children?: React.ReactNode;
  labelText?: string;
  placeholderText?: string;
}
export const EditablePhoneInput = ({
  children,
  labelText,
  placeholderText,
}: IEditablePhoneInputProps) => {
  const { phone, twoFactor } = useAppSelector((state) => state.userSettings);
  const { token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const currentPhone = '+10000000000';
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);

  const [isMountModal, setIsMountModal] = useState(false);
  const [showQRDialog, setShowQRDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getQRHandler = () => {
    setShowQRDialog(true);
  };
  const cloaseModalHandler = () => {
    setShowQRDialog(false);
  };

  const editModeButtonHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (isEditing) {
      setIsEditing(false);
      return;
    }

    setIsEditing(true);
  };

  const togglePhoneTwofactorAuth = async () => {
    try {
      if (!token) throw Error('no token or unathorised');
      setIsSubmitting(true);
      const response = await UserRepository.set2Fa(token, {
        ...twoFactor,
        phone: !twoFactor.phone,
      });
      if (response.status === EApiResponseStatus.FAILURE) {
        throw Error(response.error);
      }
      await dispatch(fetchUserSettings());
      toast.success(t('forms.formStatus.success'));
    } catch (error) {
      console.error('submit toggle phone 2fa auth error', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isEditing) inputRef.current?.focus();
  }, [isEditing]);

  useEffect(() => {
    if (showQRDialog) {
      setIsMountModal(true);
    }

    if (!showQRDialog) {
      setTimeout(() => setIsMountModal(false), 500);
    }
  }, [showQRDialog]);
  return (
    <StyledEditablePhoneInput>
      <Modal show={isMountModal}>
        <QRCodeDialog onClose={cloaseModalHandler} show={isMountModal} />
      </Modal>
      <div className="phone-input-group">
        {labelText && <label className="phone-input-label">{labelText}</label>}
        <div className="phone-input-container">
          <Input
            ref={inputRef}
            placeholder={phone ? phone : ''}
            className="phone-editable-input"
            disabled={true}
            // value={isEditing && phone ? phone : ''}
          />
          {/* <button
            className={cn('phone-editable-edit-button', isEditing && 'editing')}
            onClick={editModeButtonHandler}
          >
            <EditButton className="phone-editable-edit-button__icon" />
          </button> */}
        </div>
      </div>
      <div className="phone-input-controls">
        <Toggler on={twoFactor.phone} toggle={togglePhoneTwofactorAuth} disabled={isSubmitting} />
      </div>
      <OutlinedButton onClick={getQRHandler} className="phone-editable-qr-button" square>
        {t('settingsPage.twoFactorAuth.getQR')}
      </OutlinedButton>
    </StyledEditablePhoneInput>
  );
};
