import { Footer, Layout, UserDataLayoutContainer } from 'components';
import {
  BTCAddress,
  ChangePassword,
  Contacts,
  Donats,
  CustomTimezoneSelect,
  TwoFactorAuth,
  LanguageSelect,
  ThemeSelect,
  Notifications,
} from './components';
import { StyledContent } from './styles';
import { useAppDispatch } from 'store/store';
import { useEffect } from 'react';
import { fetchUserNotificationSettings, fetchUserSettings } from 'store/thunks/userSettingThunks';
import { Toaster } from 'react-hot-toast';

export const SettingsPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUserSettings());
    dispatch(fetchUserNotificationSettings());
  }, []);

  return (
    <Layout>
      <div>
        <Toaster />
      </div>
      <UserDataLayoutContainer pageName="settingsPage">
        <StyledContent>
          <div className="settings-main">
            <Contacts />
            <ChangePassword />
            <BTCAddress />
            <TwoFactorAuth />
          </div>
          <div className="settings-aux">
            <Donats />
            <CustomTimezoneSelect />
            <LanguageSelect />
            <ThemeSelect />
          </div>
          <Notifications />
          <Footer smaller />
        </StyledContent>
      </UserDataLayoutContainer>
    </Layout>
  );
};
