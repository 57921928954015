import { StyledInput, StyledInputGroup, StyledPhoneInput } from './styles';
import { useTranslation } from 'react-i18next';

interface IPhoneInputSimpleProps extends React.InputHTMLAttributes<HTMLInputElement> {
  labelText?: string;
  inValid?: boolean;
  error?: string;
}

export const PhoneInputSimple = (props: IPhoneInputSimpleProps) => {
  const { t } = useTranslation();

  return (
    <StyledPhoneInput>
      {props.labelText && (
        <label className="phone-input-label" htmlFor={props.name} hidden={!props.labelText}>
          {props.labelText ? props.labelText : props.placeholder}
        </label>
      )}
      <div className="phone-input-container">
        <StyledInputGroup>
          <StyledInput
            id={props.name}
            name={props.name}
            placeholder={props.placeholder}
            autoComplete="off"
            onBlur={props.onBlur}
            onChange={props.onChange}
            className={`${props.inValid ? 'invalid' : props.inValid === undefined ? '' : 'valid'}`}
            disabled={props.disabled}
            value={props.value}
          />

          {props.error && props.inValid && (
            <div className="input-error-message">{t('forms.errors.' + props.error)}</div>
          )}
        </StyledInputGroup>
      </div>
    </StyledPhoneInput>
  );
};
