import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserRepository } from 'repositories/UserRepository';
import { IAuthState } from 'store/slices/authSlice';
import { EApiResponseStatus } from 'types/api';
import {
  IReferralPayout,
  IReferralReward,
  IReferralStats,
  IReferralUser,
  TReferralPayoutsListQuery,
  TReferralRewardsListQuery,
  TReferralUsersListQuery,
} from 'types';
import { IUserReferalsState } from 'store/slices/userReferralSlice';

export const fetchReferralStats = createAsyncThunk<
  IReferralStats,
  void,
  { state: { auth: IAuthState } }
>('userReferral/referralStats', async (_, { getState }) => {
  const {
    auth: { token },
  } = getState();
  if (!token) throw Error('no token');

  const referralStatsResponse = await UserRepository.getReferralStats(token);
  console.log('referralStatsResponse', referralStatsResponse);

  if (referralStatsResponse.status === EApiResponseStatus.FAILURE)
    throw Error(referralStatsResponse.error);

  const { data } = referralStatsResponse;
  const { balance, level, nextLevel, link, hashRate } = data[0];
  const referralStats: IReferralStats = {
    referralCurrentBalance: referralStatsResponse.data[0].balance.toFixed(2),
    referralCurrentRate: hashRate.toFixed(),
    referralLevel: level,
    referralMaxRate: '0',
    referralPercent: 0.5,
    referralLinkAddress: link,
  };
  // test purpose only

  return referralStats;
});

export const fetchReferralUsersList = createAsyncThunk<
  { users: IReferralUser[]; count: number; size: number },
  void,
  { state: { auth: IAuthState; userReferrals: IUserReferalsState } }
>('userReferral/referralUserList', async (_, { getState }) => {
  const {
    auth: { token },
    userReferrals: {
      referralUsersList: { currentPage, itemsPerPage },
    },
  } = getState();
  if (!token) throw Error('no token');

  const params: TReferralUsersListQuery = {
    page: String(currentPage - 1),
    size: String(itemsPerPage),
  };

  const referralUsersListResponse = await UserRepository.getReferralUsersList(token, params);
  if (referralUsersListResponse.status === EApiResponseStatus.FAILURE)
    throw Error(referralUsersListResponse.error);

  // test purpose only
  return { users: [], count: 10, size: 10 };
});

export const fetchReferralRewardsList = createAsyncThunk<
  { rewards: IReferralReward[]; count: number; size: number },
  void,
  { state: { auth: IAuthState; userReferrals: IUserReferalsState } }
>('userReferral/referralRewardsList', async (_, { getState }) => {
  const {
    auth: { token },
    userReferrals: {
      referralRewardsList: { currentPage, itemsPerPage },
    },
  } = getState();
  if (!token) throw Error('no token');
  const params: TReferralRewardsListQuery = {
    page: String(currentPage - 1),
    size: String(itemsPerPage),
  };
  const referralUsersListResponse = await UserRepository.getReferralRewards(token, params);
  if (referralUsersListResponse.status === EApiResponseStatus.FAILURE)
    throw Error(referralUsersListResponse.error);

  // test purpose only
  return { rewards: [], count: 10, size: 10 };
});

export const fetchReferralsPayoutsList = createAsyncThunk<
  { payouts: IReferralPayout[]; count: number; size: number },
  void,
  { state: { auth: IAuthState; userReferrals: IUserReferalsState } }
>('userReferral/referralPayoutsList', async (_, { getState }) => {
  const {
    auth: { token },
    userReferrals: {
      referralPayoutsList: { currentPage, itemsPerPage },
    },
  } = getState();
  if (!token) throw Error('no token');
  const params: TReferralPayoutsListQuery = {
    page: String(currentPage - 1),
    size: String(itemsPerPage),
  };
  const referralUsersListResponse = await UserRepository.getReferralPayouts(token, params);
  if (referralUsersListResponse.status === EApiResponseStatus.FAILURE)
    throw Error(referralUsersListResponse.error);

  // test purpose only
  return { payouts: [], count: 10, size: 10 };
});
