import { TColorTheme } from 'App';
import { IApiConResponse } from './api';

export interface IUserSettings {
  phone: string;
  email: string;
  telegram: string;
  password: string;
  btcAddress: string;
  twoFactor: {
    phone: boolean;
    email: boolean;
  };
  donate: number;
  timeZone: string;
  language: string;
  theme: TColorTheme;
}

export interface IUserSettingsResponse extends IApiConResponse {
  data: IUserSettings[];
}

export enum EUserNotificationType {
  EMAIL = 'EMAIL',
  TELEGRAM = 'TELEGRAM',
}
export interface IUserNotificationSettings {
  email: {
    userId: number;
    type: EUserNotificationType.EMAIL;
    payouts: boolean;
    worker: boolean;
    login: boolean;
    news: boolean;
    referral: boolean;
  };
  telegram: {
    userId: number;
    type: EUserNotificationType.TELEGRAM;
    payouts: boolean;
    worker: boolean;
    login: boolean;
    news: boolean;
    referral: boolean;
  };
}

export interface IUserNotificationSettingsResponse extends IApiConResponse {
  data: IUserNotificationSettings;
}
