import { useTranslation } from 'react-i18next';
import { StyledReferralUsersList } from './styles';
import {
  BorderedWrapper,
  CustomCalendar,
  DisplayItemsPerPageSelect,
  Paginator,
  Search,
} from 'components';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-full.svg';
import { generateTestReferalUsers } from 'data/mockReferals';
import { filterReferralUsers, formateDate, formateTime, getSortedReferralUSers } from 'utils';
import { IReferralUser, TSortIReferralUserByHeaderTitle } from 'types';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'store/store';
import { fetchReferralUsersList } from 'store/thunks/userReferralThunks';
interface IReferralUsersListProps {
  children?: React.ReactNode;
}
export const ReferralUsersList = ({ children }: IReferralUsersListProps) => {
  const fetchedUsers = generateTestReferalUsers();
  const { t } = useTranslation();

  const DEFAULT_ITEMS_PER_PAGE_QUANTITY = 10;
  const DEFAULT_PAGE_NUMBER = 1;

  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE_QUANTITY);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);

  const [sortIncr, setSortIncr] = useState(true);
  const [searchFilter, setSearchFilter] = useState('');
  const [dateFilter, setDateFilter] = useState<{ from: Date; to: Date } | null>(null);
  const [sortBy, setSortBy] = useState<{ sortBy: keyof IReferralUser; incr: boolean }>({
    sortBy: 'email',
    incr: true,
  });

  const dispatch = useAppDispatch();

  const changeCurrentPageHandler = (currentPage: number) => {
    setCurrentPage(currentPage);
  };
  const sortHandler = (sortBy: TSortIReferralUserByHeaderTitle) => {
    setSortBy({ sortBy, incr: !sortIncr });
    setSortIncr((prev) => !prev);
  };

  const setSearchFilterHandler = (value: string) => {
    setSearchFilter(value);
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  const changeDateRangeHandler = (range: { from: Date; to: Date }) => {
    setDateFilter(range);
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  const filteredUsers = useMemo(
    () => filterReferralUsers(fetchedUsers, { date: dateFilter }),
    [dateFilter, fetchedUsers],
  );
  const searchFilteredUSers = useMemo(
    () =>
      searchFilter.length === 0
        ? filteredUsers
        : filteredUsers.filter((user) =>
            user.email.toLowerCase().includes(searchFilter.toLowerCase()),
          ),
    [searchFilter, filteredUsers],
  );

  const sortedUsers = useMemo(
    () =>
      sortBy.sortBy === null
        ? searchFilteredUSers
        : getSortedReferralUSers(searchFilteredUSers, sortBy.sortBy, sortBy.incr),
    [sortBy, searchFilteredUSers],
  );

  const pagesQuanity = Math.ceil(sortedUsers.length / currentItemsPerPage);

  const paginatedUsers = useMemo(
    () =>
      sortedUsers.slice(currentItemsPerPage * (currentPage - 1), currentItemsPerPage * currentPage),
    [sortedUsers, currentItemsPerPage, currentPage],
  );

  useEffect(() => {
    dispatch(fetchReferralUsersList());
  }, []);

  return (
    <StyledReferralUsersList>
      <BorderedWrapper>
        <div className="referral-list-controls-container">
          <h2 className="referral-list-heading">{t('referralsPage.referralUsersList.heading')}</h2>
          <div className="referral-list-controls">
            <CustomCalendar onChange={changeDateRangeHandler} />
            <Search onChange={setSearchFilterHandler} />
          </div>
        </div>
        <div className="referral-list-container">
          <div className="referral-list-header">
            <div className="referral-list-header-element email">
              <div>{t('referralsPage.referralUsersList.header.email')}</div>
              <button className="sort-by-button" onClick={() => sortHandler('email')}>
                <ArrowDownIcon className="sort-icon" />
              </button>
            </div>
            <div className="referral-list-header-element current-hashrate">
              <div>{t('referralsPage.referralUsersList.header.currentHashrate')}</div>
              <button className="sort-by-button" onClick={() => sortHandler('currnetHashRate')}>
                <ArrowDownIcon className="sort-icon" />
              </button>
            </div>
            <div className="referral-list-header-element monthly-hashrate">
              <div>{t('referralsPage.referralUsersList.header.monthlyHashrate')}</div>
              <button className="sort-by-button" onClick={() => sortHandler('monthlyHashRate')}>
                <ArrowDownIcon className="sort-icon" />
              </button>
            </div>
            <div className="referral-list-header-element date">
              <div>{t('referralsPage.referralUsersList.header.registrationDate')}</div>
              <button className="sort-by-button" onClick={() => sortHandler('registrationDate')}>
                <ArrowDownIcon className="sort-icon" />
              </button>
            </div>
          </div>
          <div className="referral-list-body">
            {paginatedUsers.map((user) => {
              const elementdate = new Date(user.registrationDate);
              return (
                <div key={user.id} className="referral-list-row">
                  <div className="referral-list-element email">{user.email}</div>
                  <div className="referral-list-element current-hashrate">
                    {user.currnetHashRate}
                  </div>
                  <div className="referral-list-element monthly-hashrate">
                    {user.monthlyHashRate}
                  </div>
                  <div className="referral-list-element date">
                    <span>{formateDate(elementdate)}</span>
                    <span className="time">
                      &nbsp;-&nbsp;
                      {`${formateTime(elementdate)}`}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          {pagesQuanity > 0 && (
            <div className="pagination-container">
              <Paginator
                currentPage={currentPage}
                pageQuantity={pagesQuanity}
                onChange={changeCurrentPageHandler}
              />
              <DisplayItemsPerPageSelect onChange={setCurrentItemsPerPage} />
            </div>
          )}
        </div>
      </BorderedWrapper>
    </StyledReferralUsersList>
  );
};
