import { Button, NewPasswordInput, PasswordInput } from 'components';
import { StyledChangePassword } from './styles';
import { useTranslation } from 'react-i18next';
import { MainSettingsComponent } from '../MainSettingsComponent';
import { Formik, FormikHelpers } from 'formik';
import { useAppDispatch, useAppSelector } from 'store/store';
import { validataionSchemaSetPasswrod } from 'lib';
import toast from 'react-hot-toast';
import { UserRepository } from 'repositories/UserRepository';
import { EApiResponseStatus } from 'types/api';
import { fetchUserSettings } from 'store/thunks/userSettingThunks';

export const ChangePassword = () => {
  const { password } = useAppSelector((state) => state.userSettings);
  const { token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const formikInitialValue: {
    oldPassword: typeof password;
    newPassword: typeof password;
  } = {
    oldPassword: password,
    newPassword: null,
  };

  const formitSetPasswordSubmitHadler = async (
    values: typeof formikInitialValue,
    formikHelpers: FormikHelpers<typeof formikInitialValue>,
  ) => {
    const { oldPassword, newPassword } = values;
    const { setFieldError, setSubmitting, resetForm } = formikHelpers;
    try {
      if (!token) throw Error('no token or unathorised');
      if (oldPassword !== password) {
        setFieldError('oldPassword', 'submittingNewPasswordWrongOldPassowrd');

        throw Error('submittingNewPasswordWrongOldPassowrd');
      }
      if (oldPassword === newPassword) {
        setFieldError('newPassword', 'submittingNewPasswordEqaulOldPassowrd');
        throw Error('submittingNewPasswordEqaulOldPassowrd');
      }
      if (!newPassword) {
        setFieldError('newPassword', 'submittingNewPasswordWrongNewPassowrd');
        throw Error('submittingNewPasswordEqaulOldPassowrd');
      }

      const response = await UserRepository.setUserNewPassword(token, newPassword);
      if (response.status === EApiResponseStatus.FAILURE) {
        throw Error(response.error);
      }

      await dispatch(fetchUserSettings());
      resetForm();

      toast.success(t('forms.formStatus.success'));
    } catch (error) {
      console.error(error);
      toast.error(t('forms.formStatus.failure'));
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <StyledChangePassword>
      <MainSettingsComponent heading={t('settingsPage.changePassword.heading')}>
        <Formik
          initialValues={formikInitialValue}
          onSubmit={formitSetPasswordSubmitHadler}
          validationSchema={validataionSchemaSetPasswrod}
        >
          {({ values, handleSubmit, handleChange, errors, isValid, isSubmitting }) => (
            <form className="change-password-form" onSubmit={handleSubmit}>
              <div className="change-password-form__input-groups">
                <div className="change-password-form__input-group">
                  <PasswordInput
                    name="oldPassword"
                    labelText={t('settingsPage.changePassword.oldPassword.label')}
                    placeholder={t('settingsPage.changePassword.oldPassword.placeholder')}
                    value={values.oldPassword ? values.oldPassword : ''}
                    onChange={handleChange}
                    error={errors.oldPassword}
                    inValid={!!errors.oldPassword && !isValid}
                  />
                </div>
                <div className="change-password-form__input-group">
                  <NewPasswordInput
                    name="newPassword"
                    labelText={t('settingsPage.changePassword.newPassword.label')}
                    placeholder={t('settingsPage.changePassword.newPassword.placeholder')}
                    value={values.newPassword ? values.newPassword : ''}
                    onChange={handleChange}
                    error={errors.newPassword}
                    inValid={!!errors.newPassword && !isValid}
                  />
                </div>
              </div>
              <Button
                type="submit"
                className="change-password-form__submit-button"
                title={t('settingsPage.changePassword.submitButton')}
                disabled={isSubmitting}
              ></Button>
            </form>
          )}
        </Formik>
      </MainSettingsComponent>
    </StyledChangePassword>
  );
};
