import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TColorTheme } from 'App';
import { fetchUserNotificationSettings, fetchUserSettings } from 'store/thunks/userSettingThunks';
import { ESliceDataFetchingStatus, TNullable } from 'types/common';
import { EUserNotificationType, IUserSettings } from 'types/settings';

export interface IUserSettingsState {
  status: ESliceDataFetchingStatus;
  errorMessage: TNullable<string>;
  phone: TNullable<IUserSettings['phone']>;
  email: TNullable<string>;
  telegram: TNullable<string>;
  password: TNullable<string>;
  btcAddress: TNullable<string>;
  twoFactor: {
    phone: boolean;
    email: boolean;
  };
  donate: TNullable<number>;
  timeZone: TNullable<string>;
  language: string;
  theme: TColorTheme;
  notificationStatus: ESliceDataFetchingStatus;
  notificationErrorMessage: TNullable<string>;
  notification: {
    email: {
      userId: TNullable<number>;
      type: EUserNotificationType.EMAIL;
      payouts: boolean;
      worker: boolean;
      login: boolean;
      news: boolean;
      referral: boolean;
    };
    telegram: {
      userId: TNullable<number>;
      type: EUserNotificationType.TELEGRAM;
      payouts: boolean;
      worker: boolean;
      login: boolean;
      news: boolean;
      referral: boolean;
    };
  };
}
const initialState: IUserSettingsState = {
  status: ESliceDataFetchingStatus.initial,
  errorMessage: null,
  phone: null,
  email: null,
  telegram: null,
  password: null,
  btcAddress: null,
  twoFactor: {
    phone: false,
    email: false,
  },
  donate: null,
  timeZone: null,
  language: 'en',
  theme: 'LIGHT',
  notificationStatus: ESliceDataFetchingStatus.initial,
  notificationErrorMessage: null,
  notification: {
    email: {
      userId: 1,
      type: EUserNotificationType.EMAIL,
      payouts: false,
      worker: false,
      login: false,
      news: false,
      referral: false,
    },
    telegram: {
      userId: 1,
      type: EUserNotificationType.TELEGRAM,
      payouts: false,
      worker: false,
      login: false,
      news: false,
      referral: false,
    },
  },
};

export const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.theme = state.theme === 'DARK' ? 'LIGHT' : 'DARK';
    },
    toggleLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchUserSettings.pending, (state) => {
        state.status = ESliceDataFetchingStatus.loading;
        state.errorMessage = null;
      })
      .addCase(fetchUserSettings.rejected, (state, { error }) => {
        state.status = ESliceDataFetchingStatus.error;
        state = initialState;
        state.errorMessage = error.message ? error.message : 'unknown error';
      })
      .addCase(fetchUserSettings.fulfilled, (state, { payload }) => {
        state.status = ESliceDataFetchingStatus.success;
        const {
          email,
          phone,
          telegram,
          password,
          btcAddress,
          twoFactor,
          donate,
          timeZone,
          language,
          theme,
        } = payload[0];

        const languageMap = new Map();
        languageMap.set('ENGLISH', 'en');
        languageMap.set('RUSSIAN', 'ru');

        state.phone = phone;
        state.email = email;
        state.telegram = telegram;
        state.password = password;
        state.btcAddress = btcAddress;
        state.twoFactor = twoFactor;
        state.donate = donate;
        state.timeZone = timeZone;
        state.language = languageMap.has(language)
          ? languageMap.get(language)
          : initialState.language;
        state.theme = theme;
      })
      .addCase(fetchUserNotificationSettings.pending, (state) => {
        state.notificationStatus = ESliceDataFetchingStatus.loading;
        state.notificationErrorMessage = null;
      })
      .addCase(fetchUserNotificationSettings.rejected, (state, { error }) => {
        state.notificationStatus = ESliceDataFetchingStatus.error;
        state.notificationErrorMessage = error.message
          ? error.message
          : 'unknown fetchUserNotificationSettings error';
      })
      .addCase(fetchUserNotificationSettings.fulfilled, (state, { payload }) => {
        state.notificationStatus = ESliceDataFetchingStatus.success;
        state.notification.email = payload.email;
        state.notification.telegram = payload.telegram;
      }),
});

export const { toggleTheme, toggleLanguage } = userSettingsSlice.actions;

export const userSettingsReducer = userSettingsSlice.reducer;
