import { useState } from 'react';
import { StyledToggler } from './styles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
interface ITogglerProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  on?: boolean;
  toggle?: () => void;
}
export const Toggler = ({ children, toggle, on, disabled }: ITogglerProps) => {
  const { t } = useTranslation();
  const [isOn, setIsOn] = useState(false);
  const toggleHandler = () => {
    if (toggle) {
      toggle();
    } else {
      setIsOn((prev) => !prev);
    }
  };
  return (
    <StyledToggler
      className={cn('toggler', (on || isOn) && 'active')}
      onClick={toggleHandler}
      disabled={disabled}
    >
      <span className="toggler_label">{t(`toggler.${on ? 'on' : 'off'}`)}</span>
    </StyledToggler>
  );
};
