import { Button } from 'components';
import { StyledBTCAddress } from './styles';
import { useTranslation } from 'react-i18next';
import { BTCInput } from 'components/shared/Input/BTCInput';
import { MainSettingsComponent } from '../MainSettingsComponent';
import { Formik, FormikHelpers } from 'formik';
import { useAppDispatch, useAppSelector } from 'store/store';
import { validataionSchemaSetBTCAddress } from 'lib';
import { VALIDATION_ERROR_USER_BTCAddress_REQUIRED } from 'constants/errorsConstants';
import { UserRepository } from 'repositories/UserRepository';
import toast from 'react-hot-toast';
import { EApiResponseStatus } from 'types/api';
import { fetchUserSettings } from 'store/thunks/userSettingThunks';
interface IBTCAddressProps {
  children?: React.ReactNode;
}
export const BTCAddress = ({ children }: IBTCAddressProps) => {
  const { btcAddress } = useAppSelector((state) => state.userSettings);
  const { token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isFocus = Boolean(new URLSearchParams(window.location.search).get('focus'));

  const formikInitialState = { btcAddress };
  const formikSetBTCAddressHandler = async (
    values: typeof formikInitialState,
    helpers: FormikHelpers<typeof formikInitialState>,
  ) => {
    const { setSubmitting, resetForm, setFieldError } = helpers;

    if (values.btcAddress === btcAddress) {
      resetForm();
      return;
    }

    try {
      if (!token) throw Error('no token or unathorised');
      if (!values.btcAddress) throw Error(VALIDATION_ERROR_USER_BTCAddress_REQUIRED);
      const response = await UserRepository.setBTCAddress(token, values.btcAddress);
      if (response.status === EApiResponseStatus.FAILURE) {
        throw Error(response.error);
      }
      await dispatch(fetchUserSettings());
      toast.success(t('forms.formStatus.success'));
      resetForm();
    } catch (error) {
      console.error('submit btc address error', error);
      toast.error(t('forms.formStatus.failure'));
      setFieldError('btcAddress', 'submittingBTCAddressError');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StyledBTCAddress>
      <MainSettingsComponent heading={t('settingsPage.btcAddressForm.heading')}>
        <Formik
          initialValues={formikInitialState}
          onSubmit={formikSetBTCAddressHandler}
          validationSchema={validataionSchemaSetBTCAddress}
          enableReinitialize
        >
          {({
            values,
            handleChange,
            handleSubmit,
            isValid,
            errors,
            isSubmitting,
            handleReset,
            resetForm,
          }) => (
            <form
              className="btc-address-form"
              onSubmit={handleSubmit}
              onReset={handleReset}
              onMouseLeave={() => {
                resetForm({ values: { btcAddress } });
              }}
            >
              <BTCInput
                name="btcAddress"
                className="btc-address-form__input"
                placeholder={t('settingsPage.btcAddressForm.placeholder')}
                isFocus={isFocus}
                value={values.btcAddress ? values.btcAddress : ''}
                onChange={handleChange}
                inValid={!isValid}
                error={errors.btcAddress}
                autoComplete="off"
              />
              <Button
                type="submit"
                className="btc-address-form__button"
                title={t('settingsPage.btcAddressForm.submitButton')}
                disabled={isSubmitting}
              ></Button>
            </form>
          )}
        </Formik>

        {children}
      </MainSettingsComponent>
    </StyledBTCAddress>
  );
};
