import { Input, Toggler } from 'components/shared';
import { StyledEditableEmailInput } from './styles';
import { ReactComponent as EditButton } from 'assets/icons/edit-icon.svg';
import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from 'store/store';
import { UserRepository } from 'repositories/UserRepository';
import { EApiResponseStatus } from 'types/api';
import { fetchUserSettings } from 'store/thunks/userSettingThunks';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
interface IEditableEmailInputProps {
  children?: React.ReactNode;
  labelText?: string;
  placeholderText?: string;
}
export const EditableEmailInput = ({ labelText }: IEditableEmailInputProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { email, twoFactor } = useAppSelector((state) => state.userSettings);
  const { token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);

  const editModeButtonHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (isEditing) {
      setIsEditing(false);
      return;
    }

    setIsEditing(true);
  };

  const toggleEmailTwofactorAuth = async () => {
    try {
      if (!token) throw Error('no token or unathorised');
      setIsSubmitting(true);
      const response = await UserRepository.set2Fa(token, {
        ...twoFactor,
        email: !twoFactor.email,
      });
      if (response.status === EApiResponseStatus.FAILURE) {
        throw Error(response.error);
      }
      await dispatch(fetchUserSettings());
      toast.success(t('forms.formStatus.success'));
    } catch (error) {
      console.error('submit toggle phone 2fa auth error', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isEditing) inputRef.current?.focus();
  }, [isEditing]);

  return (
    <StyledEditableEmailInput>
      <div className="email-input-group">
        {labelText && <label className="email-input-label">{labelText}</label>}
        <div className="email-input-container">
          <Input
            ref={inputRef}
            // placeholder={isEditing ? placeholderText : currentEmail}
            className="email-editable-input"
            disabled={true}
            placeholder={email ? email : ''}
            value={isEditing && email ? email : ''}
          />
          {/* <button
            className={cn('email-editable-edit-button', isEditing && 'editing')}
            onClick={editModeButtonHandler}
          >
            <EditButton className="email-editable-edit-button__icon" />
          </button> */}
        </div>
      </div>
      <div className="email-input-controls">
        <Toggler on={twoFactor.email} toggle={toggleEmailTwofactorAuth} disabled={isSubmitting} />
      </div>
    </StyledEditableEmailInput>
  );
};
