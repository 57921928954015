import { apiRoutes } from 'lib';
import { apiClient } from 'lib/ApiClient';
import {
  IAddWorkersGroupResponse,
  IAssignWorkerGroupToWorkerResponse,
  IDeleteWorkersGroupResponse,
  IEditWorkersGroupResponse,
  IPayoutsResponse,
  IReferralPayoutsListResponse,
  IReferralRewardsListResponse,
  IReferralUsersListResponse,
  IUSerPoolUrlReponse,
  IUserDeleteWorkerResponse,
  IUserMoveWorkerToGroupResponse,
  IUserRemoveWorkerFromGroupResponse,
  IWorker,
  IWorkerGroup,
  IWorkerHashrateResponse,
  IWorkersGroupsResponse,
  IWorkersResponse,
  TPayoutQuery,
  TReferralPayoutsListQuery,
  TReferralRewardsListQuery,
  TReferralUsersListQuery,
  TWorkerGroupId,
  TWorkerId,
} from 'types';
import { IUserHashrateData, IUserHashrateDataResponse, IUserStatResponse } from 'types/hashrate';
import mockUserStats from '__fixtures__/mockUserHashrateData.json';
import { IAccrualsResponse, TAccrualsQuery } from 'types/accruals';
import { IReferralStatsResponse } from 'types';
import {
  IUserNotificationSettings,
  IUserNotificationSettingsResponse,
  IUserSettingsResponse,
} from 'types/settings';
import { IApiConResponse } from 'types/api';

export const UserRepository = {
  hashRate: async (params: { from: string; to: string }, token: string) => {
    if (token === 'test-token') {
      console.warn('test token mode:', token);
      //@ts-expect-error ignore mock data error
      const data: IUserHashrateData[] = mockUserStats;
      const response: IUserHashrateDataResponse = {
        status: 'success',
        data,
      };
      return response;
    }
    const url = apiRoutes.apiFetchUserHashRate();
    const queryParams = new URLSearchParams(params);
    const response = (await apiClient.get(`${url}?${queryParams.toString()}`, {
      Authorization: `Bearer ${token}`,
    })) as IUserHashrateDataResponse;
    return response;
  },
  getUserStat: async (token: string) => {
    const url = apiRoutes.apiFetchUserStat();
    const response = (await apiClient.get(`${url}`, {
      Authorization: `Bearer ${token}`,
    })) as IUserStatResponse;
    return response;
  },
  accountInformation: async () => {},
  miner: async () => {},
  getWorkers: async (
    token: string,
    params: { page: string; size: string; sort?: string; filter?: string },
  ): Promise<IWorkersResponse> => {
    const url = apiRoutes.apiFetchWorkers();
    const queryParams = new URLSearchParams(params);
    const response = await apiClient.get(`${url}?${queryParams.toString()}`, {
      Authorization: `Bearer ${token}`,
    });

    return response as IWorkersResponse;
  },
  getWorkerHashrate: async (
    token: string,
    workerId: number,
    params: { from: string; to: string },
  ) => {
    const url = apiRoutes.apiFetchWorkerHashrate(workerId);
    const queryParams = new URLSearchParams(params);
    const response = (await apiClient.get(`${url}?${queryParams.toString()}`, {
      Authorization: `Bearer ${token}`,
    })) as IWorkerHashrateResponse;
    return response;
  },
  getUserPoolLinks: async (token: string) => {
    const url = apiRoutes.apiFetchUserUrlsPath();
    const response = (await apiClient.get(url, {
      Authorization: `Bearer ${token}`,
    })) as IUSerPoolUrlReponse;
    return response;
  },
  deleteWorker: async (workerId: TWorkerId, token: string): Promise<IUserDeleteWorkerResponse> => {
    const apiURL = apiRoutes.apiDeleteWorker();
    return apiClient.post(
      apiURL,
      { workerId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  moveWorkerToGroup: async (
    workerId: TWorkerId,
    groupId: TWorkerGroupId,
    token: string,
  ): Promise<IUserMoveWorkerToGroupResponse> => {
    const apiURL = apiRoutes.apiAssignWorkerGroupToWorker();
    return apiClient.post(
      apiURL,
      { workerId, groupId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  removeWorkerFromGroup: async (
    workerId: TWorkerId,
    groupId: TWorkerGroupId,
    token: string,
  ): Promise<IUserRemoveWorkerFromGroupResponse> => {
    const apiURL = apiRoutes.apiUnassignWorkerGroupToWorker();
    return apiClient.post(
      apiURL,
      { workerId, groupId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  getWorkersGroups: async (token: string): Promise<IWorkersGroupsResponse> => {
    const apiURL = apiRoutes.apiFetchWorkersGroups();
    const workersGroupsResponse = (await apiClient.get(apiURL, {
      Authorization: `Bearer ${token}`,
    })) as IWorkersGroupsResponse;
    return workersGroupsResponse;
  },
  addWorkersGroup: async (groupName: string, token: string) => {
    const apiURL = apiRoutes.apiAddWorkersGroup();
    const addWorkersGroupResponse = (await apiClient.post(
      apiURL,
      { name: groupName },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )) as IAddWorkersGroupResponse;
    return addWorkersGroupResponse;
  },
  removeWorkesGroup: async (
    groupData: {
      name: IWorkerGroup['name'];
      id: IWorkerGroup['id'];
    },
    token: string,
  ) => {
    const apiURL = apiRoutes.apiDeleteWorkersGroup();
    const deleteWorkersGroupResponse = (await apiClient.post(apiURL, groupData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })) as IDeleteWorkersGroupResponse;
    return deleteWorkersGroupResponse;
  },
  removeWorkersMultipleGroups: (groups: IWorkerGroup[], token: string) => {
    return Promise.all(groups.map((group) => UserRepository.removeWorkesGroup(group, token)));
  },
  editWorkersGroup: async (
    groupData: {
      groupId: IWorkerGroup['id'];
      newName: IWorkerGroup['name'];
    },
    token: string,
  ) => {
    const apiURL = apiRoutes.apiRenameWorkersGroup();
    const editWorkersGroupResponse = (await apiClient.post(apiURL, groupData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })) as IEditWorkersGroupResponse;
    return editWorkersGroupResponse;
  },
  editWorkersMultipleGroups: async (
    groupsData: {
      groupId: IWorkerGroup['id'];
      newName: IWorkerGroup['name'];
    }[],
    token: string,
  ) => {
    return Promise.all(groupsData.map((data) => UserRepository.editWorkersGroup(data, token)));
  },
  assignWorkersGroupToWorker: async (
    groupId: IWorkerGroup['id'],
    workerId: IWorker['id'],
    token: string,
  ) => {
    const apiURL = apiRoutes.apiAssignWorkerGroupToWorker();
    const assignWorkerGroupResponse = (await apiClient.post(
      apiURL,
      { groupId, workerId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )) as IAssignWorkerGroupToWorkerResponse;
    return assignWorkerGroupResponse;
  },
  assignWorkersGroupToMultipleWorker: async (
    groupId: IWorkerGroup['id'],
    workerIds: IWorker['id'][],
    token: string,
  ) => {
    const responses = Promise.all(
      workerIds.map((workerId) =>
        UserRepository.assignWorkersGroupToWorker(groupId, workerId, token),
      ),
    );
    return responses;
  },

  getPayouts: async (token: string, params: TPayoutQuery) => {
    const url = apiRoutes.apiFetchPayouts();
    const queryParams = new URLSearchParams(params);
    const response = apiClient.get(`${url}?${queryParams.toString()}`, {
      Authorization: `Bearer ${token}`,
    });
    return response as Promise<IPayoutsResponse>;
  },

  getAccruals: async (token: string, params: TAccrualsQuery) => {
    const url = apiRoutes.apiFetchAccruals();
    const queryParams = new URLSearchParams(params);
    const response = apiClient.get(`${url}?${queryParams.toString()}`, {
      Authorization: `Bearer ${token}`,
    });
    return response as Promise<IAccrualsResponse>;
  },

  getReferralStats: async (token: string) => {
    const url = apiRoutes.apiFetchReferralStats();
    const response = apiClient.get(`${url}`, {
      Authorization: `Bearer ${token}`,
    });
    return response as Promise<IReferralStatsResponse>;
  },

  getReferralUsersList: async (token: string, params: TReferralUsersListQuery) => {
    const url = apiRoutes.apiFetchReferralUsers();
    const queryParams = new URLSearchParams(params);
    const response = apiClient.get(`${url}?${queryParams.toString()}`, {
      Authorization: `Bearer ${token}`,
    });
    return response as Promise<IReferralUsersListResponse>;
  },

  getReferralRewards: async (token: string, params: TReferralRewardsListQuery) => {
    const url = apiRoutes.apiFetchReferralRewards();
    const queryParams = new URLSearchParams(params);
    const response = apiClient.get(`${url}?${queryParams.toString()}`, {
      Authorization: `Bearer ${token}`,
    });
    return response as Promise<IReferralRewardsListResponse>;
  },

  getReferralPayouts: async (token: string, params: TReferralPayoutsListQuery) => {
    const url = apiRoutes.apiFetchReferralPayouts();
    const queryParams = new URLSearchParams(params);
    const response = apiClient.get(`${url}?${queryParams.toString()}`, {
      Authorization: `Bearer ${token}`,
    });
    return response as Promise<IReferralPayoutsListResponse>;
  },
  getUserSettings: async (token: string) => {
    const url = apiRoutes.apiFetchSettings();
    const response = apiClient.get(`${url}`, {
      Authorization: `Bearer ${token}`,
    });
    return response as Promise<IUserSettingsResponse>;
  },
  setUserPhone: async (token: string, phone: string) => {
    const url = apiRoutes.apiUserSettingsPhone();
    const response = apiClient.post(
      `${url}`,
      { phone },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response;
  },
  setUserEmail: async (token: string, email: string) => {
    const url = apiRoutes.apiUserSettingsEmail();
    const response = apiClient.post(
      `${url}`,
      { email },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response;
  },
  setUserTelegram: async (token: string, telegram: string) => {
    const url = apiRoutes.apiUserSettingsTelegram();
    const response = apiClient.post(
      `${url}`,
      { telegram },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response;
  },
  setUserNewPassword: async (token: string, password: string) => {
    const url = apiRoutes.apiUserSettingsNewPassword();

    const response = apiClient.post(
      `${url}`,
      { password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response;
  },
  setBTCAddress: async (token: string, btcAddress: string) => {
    const url = apiRoutes.apiUserSettingsBTCAddress();
    const response = apiClient.post(
      `${url}`,
      { btcAddress },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response;
  },
  set2Fa: async (token: string, twoFactor: { phone: boolean; email: boolean }) => {
    const url = apiRoutes.apiUserSettings2fa();
    const response = apiClient.post(`${url}`, twoFactor, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  },
  getUserNotificationSettings: async (token: string) => {
    const url = apiRoutes.apiUserNotificationSettings();
    const response = apiClient.get(url, {
      Authorization: `Bearer ${token}`,
    }) as Promise<IUserNotificationSettingsResponse>;
    return response;
  },
  setUserNotificationSettings: async (
    notificationSettings: IUserNotificationSettings,
    token: string,
  ) => {
    const url = apiRoutes.apiUserNotificationSettings();
    const response = apiClient.post(url, notificationSettings, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }) as Promise<IUserNotificationSettingsResponse>;
    return response;
  },
  setUserLanguage: async (languageSettings: { language: string }, token: string) => {
    const url = apiRoutes.apiUserLanguage();
    const response = apiClient.post(url, languageSettings, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }) as Promise<IApiConResponse>;
    return response;
  },
};
