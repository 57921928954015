import { BorderedWrapper } from 'components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyIcon } from 'assets/icons/copy-icon.svg';
import { StyledReferralBalance } from './styles/StyledReferralBalance';
import { useAppSelector } from 'store/store';
import { ESliceDataFetchingStatus } from 'types/common';

interface IReferralBalanceProps {
  children?: React.ReactNode;
}
export const ReferralBalance = ({ children }: IReferralBalanceProps) => {
  const { t } = useTranslation();

  const { referralStatsStatus, referralStats } = useAppSelector((state) => state.userReferrals);
  const { referralLinkAddress, referralCurrentBalance, referralPercent } = referralStats;

  const currency = 'BTC';
  const copyUrlHandler = () => {
    navigator.clipboard.writeText(referralLinkAddress ? referralLinkAddress : 'n/a');
  };
  return (
    <StyledReferralBalance>
      <BorderedWrapper>
        {referralStatsStatus === ESliceDataFetchingStatus.loading ? (
          ' Loading...'
        ) : (
          <div className="content">
            <h2 className="balance-heading">{t('referralsPage.balance.heading')}</h2>
            <div className="balance">
              <span className="balance-value">
                {referralCurrentBalance ? referralCurrentBalance : 'n/a'}
              </span>
              <span className="balance-currnecy">{currency}</span>
            </div>
            <p className="update-time">{t('referralsPage.balance.updateTime')}</p>
            <div className="referal-link-container">
              <div className="referal-link">
                <p className="referal-link-heading">
                  {t('referralsPage.balance.referalLink.link')}
                </p>
                <p className="referal-link-value">
                  <span className="referal-link-address">
                    {referralLinkAddress ? referralLinkAddress : 'link address is unavailable'}
                  </span>
                  <button
                    className="copy-link-button"
                    onClick={copyUrlHandler}
                    disabled={!referralLinkAddress}
                  >
                    <CopyIcon className="copy-link-button-icon" />
                  </button>
                </p>
              </div>
              <div className="referal-link-interest">
                <p className="referal-link-interest-heading">
                  {t('referralsPage.balance.referalLink.interest')}
                </p>
                <p className="referal-link-interest-value">
                  {referralPercent !== null ? `${referralPercent} %` : 'n/a'}
                </p>
              </div>
            </div>
          </div>
        )}
      </BorderedWrapper>
    </StyledReferralBalance>
  );
};
