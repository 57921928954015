import { useEffect, useRef } from 'react';
import { Input } from './Input';
import { StyledBTCInput } from './styles/StyledBTCInput';

interface IBTCInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  labelText?: string;
  isFocus?: boolean;
  inValid?: boolean;
  error?: string;
}
export const BTCInput = ({
  labelText,
  placeholder,
  className,
  isFocus,
  autoComplete,

  ...props
}: IBTCInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef && isFocus) {
      inputRef.current?.focus();
    }
  }, []);
  return (
    <StyledBTCInput className={className}>
      {labelText && <label className="email-input-label">{labelText}</label>}
      <Input placeholder={placeholder} ref={inputRef} autoComplete={autoComplete} {...props} />
    </StyledBTCInput>
  );
};
