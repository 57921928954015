import { EmailInput, OutlinedButton, PhoneInputSimple, TelegramInput } from 'components';
import { StyledContacts } from './styles';
import { useTranslation } from 'react-i18next';
import { MainSettingsComponent } from '../MainSettingsComponent';
import { useAppDispatch, useAppSelector } from 'store/store';
import toast from 'react-hot-toast';
import { UserRepository } from 'repositories/UserRepository';
import { EApiResponseStatus } from 'types/api';
import { fetchUserSettings } from 'store/thunks/userSettingThunks';
import { Formik, FormikHelpers } from 'formik';
import {
  validataionSchemaSetEmail,
  validataionSchemaSetTelegram,
  validationSchemaSetPhone,
} from 'lib';
import {
  VALIDATION_ERROR_USER_PHONE_INVALID,
  VALIDATION_ERROR_USER_TELEGRAM_INVALID,
} from 'constants/errorsConstants';
import { TNullable } from 'types/common';

export const Contacts = () => {
  const { t } = useTranslation();

  return (
    <StyledContacts>
      <MainSettingsComponent heading={t('settingsPage.contacts.heading')}>
        <PhoneNumberForms />
        <EmailForm />
        <TelegramForm />
      </MainSettingsComponent>
    </StyledContacts>
  );
};

const TelegramForm = () => {
  const { telegram } = useAppSelector((state) => state.userSettings);
  const { token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const formikEmailInitial = { userTelegram: telegram };

  const formikHandleTelegramSubmit = async (
    values: typeof formikEmailInitial,
    helpers: FormikHelpers<typeof formikEmailInitial>,
  ) => {
    const { userTelegram } = values;
    const { setSubmitting, resetForm, setFieldError } = helpers;
    if (userTelegram === telegram) {
      resetForm();
      return;
    }

    try {
      if (!token) throw Error('no token or unathorised');
      if (!userTelegram) throw Error(VALIDATION_ERROR_USER_TELEGRAM_INVALID);

      const response = await UserRepository.setUserTelegram(token, userTelegram);
      if (response.status === EApiResponseStatus.FAILURE) {
        throw Error(response.error);
      }
      await dispatch(fetchUserSettings());
      toast.success(t('forms.formStatus.success'));
      resetForm();
    } catch (error) {
      console.error('submit user phone error', error);
      const errorMessageText = t('forms.errors.submittingTelegramError');
      toast.error(errorMessageText);
      setFieldError('userTelegram', 'submittingTelegramError');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={formikEmailInitial}
      onSubmit={formikHandleTelegramSubmit}
      enableReinitialize
      validationSchema={validataionSchemaSetTelegram}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        handleChange,
        handleBlur,
        handleReset,
        dirty,
        errors,
        resetForm,
        values,
      }) => {
        return (
          <form
            className="settings-contacts-form"
            onSubmit={handleSubmit}
            onReset={handleReset}
            onMouseLeave={() => {
              resetForm({ values: { userTelegram: telegram } });
            }}
          >
            <div className="settings-contacts-form__input-group">
              <TelegramInput
                labelText={t('settingsPage.contacts.telegramInput.label')}
                placeholder={t('settingsPage.contacts.telegramInput.placeholder')}
                name="userTelegram"
                inValid={dirty ? !isValid : undefined}
                onChange={handleChange}
                error={errors.userTelegram}
                value={values.userTelegram ? values.userTelegram : ''}
                onBlur={handleBlur}
              />
            </div>
            <OutlinedButton
              type="submit"
              className="settings-contacts-form__submit-button long"
              square
              disabled={isSubmitting}
            >
              {t('settingsPage.contacts.telegramInput.submitButton')}
            </OutlinedButton>
          </form>
        );
      }}
    </Formik>
  );
};

const EmailForm = () => {
  const { email } = useAppSelector((state) => state.userSettings);
  const { token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const formikEmailInitial = { userEmail: email };

  const formikHandleEmailSubmit = async (
    values: typeof formikEmailInitial,
    helpers: FormikHelpers<typeof formikEmailInitial>,
  ) => {
    const { userEmail } = values;
    const { setSubmitting, resetForm, setFieldError } = helpers;
    if (userEmail === email) {
      resetForm();
      return;
    }

    try {
      if (!token) throw Error('no token or unathorised');
      if (!userEmail) throw Error(VALIDATION_ERROR_USER_PHONE_INVALID);

      const response = await UserRepository.setUserEmail(token, userEmail);
      if (response.status === EApiResponseStatus.FAILURE) {
        throw Error(response.error);
      }
      await dispatch(fetchUserSettings());
      toast.success(t('forms.formStatus.success'));
      resetForm();
    } catch (error) {
      console.error('submit user phone error', error);
      const errorMessageText = t('forms.errors.submittingEmailError');
      toast.error(errorMessageText);
      setFieldError('userEmail', 'submittingEmailError');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validataionSchemaSetEmail}
      initialValues={formikEmailInitial}
      onSubmit={formikHandleEmailSubmit}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        handleChange,
        handleBlur,
        handleReset,
        dirty,
        errors,
        resetForm,
        values,
      }) => (
        <form
          className="settings-contacts-form"
          onSubmit={handleSubmit}
          onReset={handleReset}
          onMouseLeave={() => {
            resetForm({ values: { userEmail: email } });
          }}
        >
          <div className="settings-contacts-form__input-group">
            <EmailInput
              name="userEmail"
              labelText={t('settingsPage.contacts.emailInput.label')}
              placeholder={t('settingsPage.contacts.emailInput.placeholder')}
              inValid={dirty ? !isValid : undefined}
              onChange={handleChange}
              error={errors.userEmail}
              value={values.userEmail ? values.userEmail : ''}
              onBlur={handleBlur}
            />
          </div>
          <OutlinedButton
            type="submit"
            className="settings-contacts-form__submit-button long"
            square
            disabled={isSubmitting}
          >
            {t('settingsPage.contacts.emailInput.submitButton')}
          </OutlinedButton>
        </form>
      )}
    </Formik>
  );
};

const PhoneNumberForms = () => {
  const { phone } = useAppSelector((state) => state.userSettings);
  const { token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const formikInitial = { userPhone: phone };

  const formikHandlePhonenumberSubmit = async (
    values: { userPhone: TNullable<string> },
    helpers: FormikHelpers<{
      userPhone: TNullable<string>;
    }>,
  ) => {
    const { userPhone } = values;
    const { setSubmitting, resetForm, setFieldError } = helpers;
    // console.log('values', values);
    // console.log('helpers', helpers);
    if (userPhone === phone) {
      resetForm();
      return;
    }

    try {
      if (!token) throw Error('no token or unathorised');
      if (!userPhone) throw Error(VALIDATION_ERROR_USER_PHONE_INVALID);

      const response = await UserRepository.setUserPhone(token, userPhone);
      if (response.status === EApiResponseStatus.FAILURE) {
        throw Error(response.error);
      }
      await dispatch(fetchUserSettings());
      toast.success(t('forms.formStatus.success'));
      resetForm();
    } catch (error) {
      console.error('submit user phone error', error);
      const errorMessageText = t('forms.errors.submittingPhoneError');
      toast.error(errorMessageText);
      setFieldError('userPhone', 'submittingPhoneError');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchemaSetPhone}
      initialValues={formikInitial}
      onSubmit={formikHandlePhonenumberSubmit}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        handleChange,
        handleBlur,
        handleReset,
        dirty,
        errors,
        resetForm,
        values,
      }) => {
        return (
          <>
            <form
              className="settings-contacts-form"
              onSubmit={handleSubmit}
              onReset={handleReset}
              onMouseLeave={() => {
                resetForm({ values: { userPhone: phone } });
              }}
            >
              <div className="settings-contacts-form__input-group">
                <PhoneInputSimple
                  labelText={t('settingsPage.contacts.phoneInput.label')}
                  name="userPhone"
                  inValid={dirty ? !isValid : undefined}
                  onChange={handleChange}
                  error={errors.userPhone}
                  value={values.userPhone ? values.userPhone : ''}
                  onBlur={handleBlur}
                  placeholder="+123456"
                />
              </div>
              <OutlinedButton
                type="submit"
                className="settings-contacts-form__submit-button long"
                square
                disabled={isSubmitting}
              >
                {t('settingsPage.contacts.phoneInput.submitButton')}
              </OutlinedButton>
            </form>
          </>
        );
      }}
    </Formik>
  );
};
