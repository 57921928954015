import { Footer, Layout, UserDataLayoutContainer } from 'components';
import styled from 'styled-components';
import {
  ReferralBalance,
  ReferralHero,
  ReferralPayoutsList,
  ReferralRewardsList,
  ReferralUsersList,
  ReferrralLevels,
} from './components';
import { useAppDispatch } from 'store/store';
import { useEffect } from 'react';
import { fetchReferralStats } from 'store/thunks/userReferralThunks';

const StyledBasicDataContainer = styled.div`
  display: flex;
  gap: 1.2rem;
  flex-direction: column;

  margin-bottom: 4rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    gap: 2rem;

    margin-bottom: 2rem;
  }

  @media screen and (min-width: 1440px) {
    flex-direction: row;
    gap: 4.5rem;

    > * {
      flex-shrink: 1;
      flex-grow: 1;
      flex-basis: 50%;
    }
  }
`;
export const ReferralsPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchReferralStats());
  });
  return (
    <Layout>
      <UserDataLayoutContainer pageName="referralsPage">
        <ReferralHero />
        <StyledBasicDataContainer>
          <ReferrralLevels />
          <ReferralBalance />
        </StyledBasicDataContainer>
        <ReferralRewardsList />
        <ReferralPayoutsList />
        <ReferralUsersList />
        <Footer smaller />
      </UserDataLayoutContainer>
    </Layout>
  );
};
