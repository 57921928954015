import { StyledEmailInput, StyledInput, StyledInputGroup } from './styles';
import { useTranslation } from 'react-i18next';

interface ITelegrarmInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  labelText?: string;
  inValid?: boolean;
  error?: string;
}
export const TelegramInput = (props: ITelegrarmInputProps) => {
  const { t } = useTranslation();
  const inputName = props.name ? props.name : 'email';
  return (
    <StyledEmailInput>
      {props.labelText && (
        <label className="email-input-label" htmlFor={inputName}>
          {props.labelText}
        </label>
      )}
      <StyledInputGroup>
        <StyledInput
          id={props.name}
          name={inputName}
          placeholder={props.placeholder}
          autoComplete="off"
          onBlur={props.onBlur}
          onChange={props.onChange}
          className={`${props.inValid ? 'invalid' : props.inValid === undefined ? '' : 'valid'}`}
          disabled={props.disabled}
          value={props.value}
        />
        {props.error && props.inValid && (
          <div className="input-error-message">{t('forms.errors.' + props.error)}</div>
        )}
      </StyledInputGroup>
    </StyledEmailInput>
  );
};
