import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserRepository } from 'repositories/UserRepository';
import { IAuthState } from 'store/slices/authSlice';
import { EApiResponseStatus } from 'types/api';
import { IUserNotificationSettings, IUserSettings } from 'types/settings';

export const fetchUserSettings = createAsyncThunk<
  IUserSettings[],
  void,
  { state: { auth: IAuthState } }
>('userSettings/fetchUserSettings', async (_, { getState }) => {
  const {
    auth: { token },
  } = getState();
  if (!token) throw Error('no token');
  const settingsResponse = await UserRepository.getUserSettings(token);

  if (settingsResponse.status === EApiResponseStatus.FAILURE) {
    throw Error(settingsResponse.error);
  }

  return settingsResponse.data;
});

export const fetchUserNotificationSettings = createAsyncThunk<
  IUserNotificationSettings,
  void,
  { state: { auth: IAuthState } }
>('userSettings/notifications', async (_, { getState }) => {
  const {
    auth: { token },
  } = getState();
  if (!token) throw Error('no token');
  const notificationSettingsResponse = await UserRepository.getUserNotificationSettings(token);

  return notificationSettingsResponse.data;
});
