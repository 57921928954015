import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import {
  fetchReferralRewardsList,
  fetchReferralsPayoutsList,
  fetchReferralStats,
  fetchReferralUsersList,
} from 'store/thunks/userReferralThunks';
import { IReferralPayout, IReferralReward, IReferralUser } from 'types';
import { ESliceDataFetchingStatus } from 'types/common';
import { IReferralStats } from 'types';

export interface IUserReferalsState {
  referralStats: IReferralStats;
  referralStatsStatus: ESliceDataFetchingStatus;
  referralUsersListStatus: ESliceDataFetchingStatus;
  referralPayoutsListStatus: ESliceDataFetchingStatus;
  referralRewardsListStatus: ESliceDataFetchingStatus;
  referralUsersList: {
    users: IReferralUser[];
    pagesQuantity: number;
    itemsPerPage: number;
    currentPage: number;
  };
  referralRewardsList: {
    rewards: IReferralReward[];
    pagesQuantity: number;
    itemsPerPage: number;
    currentPage: number;
  };
  referralPayoutsList: {
    payouts: IReferralPayout[];
    pagesQuantity: number;
    itemsPerPage: number;
    currentPage: number;
  };
}

const initialState: IUserReferalsState = {
  referralStats: {
    referralCurrentRate: null,
    referralLevel: null,
    referralMaxRate: null,
    referralPercent: null,
    referralCurrentBalance: null,
    referralLinkAddress: null,
  },
  referralUsersList: { users: [], pagesQuantity: 1, itemsPerPage: 10, currentPage: 1 },
  referralPayoutsList: { payouts: [], pagesQuantity: 1, itemsPerPage: 10, currentPage: 1 },
  referralRewardsList: { rewards: [], pagesQuantity: 1, itemsPerPage: 10, currentPage: 1 },
  referralUsersListStatus: ESliceDataFetchingStatus.initial,
  referralStatsStatus: ESliceDataFetchingStatus.initial,
  referralPayoutsListStatus: ESliceDataFetchingStatus.initial,
  referralRewardsListStatus: ESliceDataFetchingStatus.initial,
};

export const userReferralSlice = createSlice({
  name: 'referralData',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchReferralStats.pending, (state) => {
        state.referralStatsStatus = ESliceDataFetchingStatus.loading;
      })
      .addCase(fetchReferralStats.rejected, (state) => {
        state.referralStatsStatus = ESliceDataFetchingStatus.error;
        state.referralStats = initialState.referralStats;
      })
      .addCase(fetchReferralStats.fulfilled, (state, { payload }) => {
        state.referralStatsStatus = ESliceDataFetchingStatus.success;
        state.referralStats = payload;
      })
      .addCase(fetchReferralUsersList.pending, (state) => {
        state.referralUsersListStatus = ESliceDataFetchingStatus.loading;
      })
      .addCase(fetchReferralUsersList.rejected, (state) => {
        state.referralUsersListStatus = ESliceDataFetchingStatus.error;
        state.referralUsersList = initialState.referralUsersList;
      })
      .addCase(fetchReferralUsersList.fulfilled, (state, { payload }) => {
        state.referralUsersListStatus = ESliceDataFetchingStatus.success;
        state.referralUsersList.users = payload.users;
        state.referralUsersList.pagesQuantity = Math.ceil(payload.count / payload.size);
      })
      .addCase(fetchReferralRewardsList.pending, (state) => {
        state.referralRewardsListStatus = ESliceDataFetchingStatus.loading;
      })
      .addCase(fetchReferralRewardsList.rejected, (state) => {
        state.referralPayoutsListStatus = ESliceDataFetchingStatus.error;
        state.referralRewardsList = initialState.referralRewardsList;
      })
      .addCase(fetchReferralRewardsList.fulfilled, (state, { payload }) => {
        state.referralPayoutsListStatus = ESliceDataFetchingStatus.success;
        state.referralRewardsList.rewards = payload.rewards;
        state.referralPayoutsList.pagesQuantity = Math.ceil(payload.count / payload.size);
      })
      .addCase(fetchReferralsPayoutsList.pending, (state) => {
        state.referralPayoutsListStatus = ESliceDataFetchingStatus.loading;
      })
      .addCase(fetchReferralsPayoutsList.rejected, (state) => {
        state.referralPayoutsListStatus = ESliceDataFetchingStatus.error;
        state.referralPayoutsList = initialState.referralPayoutsList;
      })
      .addCase(fetchReferralsPayoutsList.fulfilled, (state, { payload }) => {
        state.referralPayoutsListStatus = ESliceDataFetchingStatus.success;
        state.referralPayoutsList.payouts = payload.payouts;
        state.referralPayoutsList.currentPage = Math.ceil(payload.count / payload.size);
      }),
});

export const selectReferralStats = (state: RootState) => {
  return {
    ...state.userReferrals.referralStats,
    referralStatsStatus: state.userReferrals.referralStatsStatus,
  };
};

export const userReferralReducer = userReferralSlice.reducer;
